import Enum from "@/util/enum";
import EnumValue from ".";

export default class EmdisDonorState extends Enum {
  static NO: EnumValue = { code: 0, name: "NO" }; // No State
  static AV: EnumValue = { code: 1, name: "AV" }; // Available for transplantation purposes"
  static TU: EnumValue = { code: 3, name: "TU" }; // Temporarily unavailable
  static RS: EnumValue = { code: 4, name: "RS" }; // Reserved
  static DE: EnumValue = { code: 5, name: "DE" }; // Deleted, permanently unavailable
}
