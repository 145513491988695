<template>
  <Button @click="show" v-bind="buttonBind" />
  <Dialog v-model:visible="visible" modal>
    <template #header>
      <b id="header">{{ header }}</b>
    </template>
    <div v-if="mode == ReqType.NEW" class="p-grid p-ai-start">
      <div class="p-col-12 p-mt-1">
        <Checkbox v-model="toggle" binary class="p-mr-2" />
        <label v-if="toggle">Deselect all</label>
        <label v-else>Select all</label>
      </div>
      <div v-for="m in model" :key="m" class="p-col-4 p-grid">
        <Checkbox :value="m" v-model="value[m]" class="p-col-2" binary />
        <label class="p-col-10">{{ m }}</label>
      </div>
    </div>
    <div v-else>
      Confirm reservation request?
    </div>
    <template #footer>
      <div class="p-grid p-ai-start">
        <Button
          label="Confirm"
          @click="confirm"
          class="p-button-rounded p-button-success"
          :disabled="confirmButtonDisable"
        />
        <Button
          label="Cancel"
          @click="visible = false"
          class="p-button-rounded p-button-outlined p-button-secondary"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ReqDonWrapper from "@/util/reqDonWrapper";
import AtaEmdReq from "@/model/ataEmdReq";
import Dialog from "primevue/dialog";
import Checkbox from "primevue/checkbox";
import ToggleButton from "primevue/togglebutton";
import AtaTmpPat from "@/model/ataTmpPat";
import ReqStatus from "@/model/enums/reqStatus";
import ReqType from "@/model/enums/reqType";
import ActiveTypingReq from "@/model/enums/activeTypingReq";
import EnumValue from "@/model/enums";
import { namespace } from "vuex-class";
const recommendedDonors = namespace("recommendedDonors");

@Options({
  components: { Dialog, Checkbox, ToggleButton },
  props: { don: Object as () => ReqDonWrapper, mode: Object as () => EnumValue }
})
export default class RecRequest extends Vue {
  @recommendedDonors.State
  pat!: AtaTmpPat;

  visible = false;
  don!: ReqDonWrapper;
  mode!: EnumValue;
  model = [
    "A",
    "B",
    "C",
    "DRB1",
    "DRB3",
    "DRB4",
    "DRB5",
    "DQA1",
    "DQB1",
    "DPA1",
    "DPB1"
  ];
  value: Record<string, boolean> = {};

  ReqType = ReqType;

  show() {
    this.value = {};
    this.visible = true;
  }

  get confirmButtonDisable() {
    if (this.mode == ReqType.NEW) return this.isEmpty();
    if (this.mode == ReqType.RESREQ) return false;
    return true;
  }

  isEmpty() {
    return !Object.values(this.value).includes(true);
  }

  isAll() {
    const v = Object.values(this.value);
    return v.length == this.model.length && !v.includes(false);
  }

  get toggle(): boolean {
    return this.isAll();
  }

  set toggle(v: boolean) {
    if (v) this.model.forEach(m => (this.value[m] = true));
    else this.value = {};
  }

  get header() {
    if (this.mode == ReqType.NEW) return "Request";
    if (this.mode == ReqType.RESREQ) return "Reservation";
    return "Unknown";
  }

  confirm() {
    // console.log(this.request);
    const r = this.request;
    if (this.mode == ReqType.NEW) {
      r.details = this.details;
      this.sendRequest(r);
      this.don.don.activeTypingReq = +ActiveTypingReq.PENDING.code;
    } else if (this.mode == ReqType.RESREQ) {
      r.reqtype = +ReqType.RESREQ.code;
      r.details = "Reservation";
      this.sendRequest(r);
      this.don.don.isReqForRes = true;
    } else {
      console.error("Unknown request button mode:'" + this.mode + "'");
    }
    this.visible = false;
  }

  get buttonBind() {
    if (this.mode == ReqType.NEW)
      return {
        label: ActiveTypingReq.byCode(this.don.don.activeTypingReq),
        disabled:
          this.don.don.activeTypingReq != +ActiveTypingReq.SEND_REQUEST.code,
        class: "p-button-success"
      };
    if (this.mode == ReqType.RESREQ)
      return {
        label: this.don.don.isReqForRes ? "Sent" : "Reserve",
        disabled: this.don.don.isReqForRes,
        class: this.don.don.isReqForRes ? "p-button-success" : "p-button-info",
        icon: this.don.don.isReqForRes ? "pi pi-check" : ""
      };
    return {
      label: "Unknown",
      disabled: true,
      class: "p-button-success"
    };
  }

  get details() {
    return this.model.map(m => (this.value[m] ? "H" : "-")).join("");
  }

  get request(): AtaEmdReq {
    return {
      id: undefined,
      patientid: this.pat.patientid,
      donorgrid: this.don.don.grid,
      status: +ReqStatus.PENDING.code,
      reqtype: +ReqType.NEW.code,
      dateseen: undefined,
      reserveduntil: undefined,
      details: "",
      news: undefined,
      a1: undefined,
      a2: undefined,
      b1: undefined,
      b2: undefined,
      c1: undefined,
      c2: undefined,
      drb11: undefined,
      drb12: undefined,
      drb31: undefined,
      drb32: undefined,
      drb41: undefined,
      drb42: undefined,
      drb51: undefined,
      drb52: undefined,
      dqa11: undefined,
      dqa12: undefined,
      dqb11: undefined,
      dqb12: undefined,
      dpa11: undefined,
      dpa12: undefined,
      dpb11: undefined,
      dpb12: undefined
    };
  }

  @recommendedDonors.Action
  sendRequest!: (request: AtaEmdReq) => void;
}
</script>

<style scoped>
#header {
  width: 95%;
  padding-bottom: 5px;
  border-bottom: 2px solid grey;
  font-size: 20px;
}
</style>
