// import EnumValue from ".";

import Enum from "@/util/enum";
import EnumValue from ".";

export default class ReqStatus extends Enum {
  static PENDING: EnumValue = { name: "Pending", code: 0 };
  static DECLINED: EnumValue = { name: "Request Declined", code: 1 };
  static SENT: EnumValue = { name: "Request Sent", code: 2 };
  static RESULTS_RECEIVED: EnumValue = { name: "Results Received", code: 3 };
  static CANCELED: EnumValue = { name: "Request Cancelled", code: 4 };
  static NO_RES: EnumValue = { name: "No Results", code: 5 };
}

/*
0 - PENDING (po vytvoření požadavku v TC),
1 - DECLINED (po zamítnutí požadavku v registru),
2 - SENT (po odeslání požadavku z registru do EMDISu),
3 -  RESULTS_RECEIVED,
4 -  CANCELED,
5 -  NO_RES
*/
